/**
 * Created by fanjun on 2022/08/31.
 * 在途费用上报
 */

import React from 'react';
import { PropTypes } from 'prop-types';
import localforage from 'localforage';
import {
  SlideDrager,
  Button,
  CardBox,
  Select,
  PureTextarea,
  PureInput,
  UploadFile,
  DateTimePicker,
  Radio,
  DataList,
  ShowInfo,
  PopUp,
  Icon,
  Switch,
  ModalDialog,
} from 'components';
import SimilarFee from 'components/commoncomponents/similarFee';
import { showInfo, validateFieldsOf, bem, fetchJAVA } from 'utils';
import { ERROR, CHECK, INFO, FLOAT_P_6 } from 'constants';
import { prefixCls } from './index.scss';
import orgSug from 'utils/sug/org';
import carBatch from 'utils/sug/carBatch';
import truckSug from 'utils/sug/truck';
import driverSug from 'utils/sug/driver';
import serviceProviderSug from 'utils/sug/serviceProvider';
import entranceExitOilStationSug from 'utils/sug/entranceExitOilStation';
import oilCardSug from 'utils/sug/oilCard';
import etcSug from 'utils/sug/etc';
import inTransitTableConf from './constants';
import { withPopUpI18n } from '@/utils/i18n/context';
import _ from 'lodash';
import Big from 'big.js';
import { assignedApprover } from 'components/commoncomponents/ApproverSelection/index';

const { FormItem } = CardBox;
const cls = bem(prefixCls);
const feeReportStore = localforage.createInstance({
  driver: localforage.INDEXEDDB, // 选择存储引擎，如IndexedDB
  name: 'cyt_frontend', // 数据库名称
  version: 1, // 数据库版本号
  storeName: 'fee_report', // 存储对象的名称
});
const feeRepeatKey = `${window.group_id}_${window.user_id}_repeat_fee_visible`;

class FeeReport extends React.Component {
  static propTypes = {
    opType: PropTypes.oneOf(['add', 'edit', 'view']),
    feeType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onlyShowContent: PropTypes.bool,
    // 是否为费用审核
    isAudit: PropTypes.bool,
    setLoading: PropTypes.func,
    onSuccess: PropTypes.func,
    close: PropTypes.func,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    // eslint-disable-next-line react/forbid-prop-types
    i18n: PropTypes.object,
  };
  constructor(props) {
    super(props);
    let feeConfig = {};
    if (props.feeType) {
      feeConfig = inTransitTableConf(props.i18n).find(i => i.feeType === +props.feeType) || {};
    }
    this.state = {
      data: {},
      common_approval: {},
      originData: {},
      relation_info: {},
      enums: {},
      originEnums: {},
      onwayFeeField: null,
      feeConfig,
      loading: false,
      show_gps_miles: 0,
      repeatFeeVisible: false,
      // 是否存在疑似重复
      hasSuspDupes: false,
    };
  }
  async componentDidMount() {
    this.getData(true);

    const repeatFeeVisible = await this.getRepeatFeeVisible();
    this.setState({ repeatFeeVisible });
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.id !== nextProps.id) {
      this.getData(true, nextProps);
    }
  }
  /**
   * @param {是否初次请求} first
   */
  getData = async (first = false, np) => {
    const { id, feeType } = np || this.props;
    const req = {};
    if (first) {
      req.id = id;
    } else {
      req.company_id = this.state.data?.company_id?.id;
    }
    this.setState({ loading: true });
    this.props?.setLoading?.(true);
    const res = await fetchJAVA('/cmm-batch/japi/batch/transitFee/employee/report/init', {
      method: 'POST',
      body: {
        req,
      },
    });
    this.setState({ loading: false });
    this.props?.setLoading?.(false);
    if (+res.errno !== 0) {
      return showInfo(ERROR, res.errmsg);
    }
    const { enumerations = {}, data = {}, common_approval = {}, relation_info = {}, has_susp_dupes = false } = res?.res;
    const feeIdEnum = enumerations?.fee_id?.filter(i => i.ext_info.type === (feeType || data?.fee_type));
    const enums = { ...enumerations, fee_id: feeIdEnum };
    const feeInfo = Object.assign(data || {}, { batch: data?.b_link_id });
    const stateObj = {
      data: feeInfo,
      originData: _.cloneDeep(feeInfo),
      enums,
      originEnums: _.cloneDeep(enums),
      common_approval,
      relation_info: relation_info || {},
      hasSuspDupes: has_susp_dupes,
    };
    if (id && first) {
      const feeConfig = inTransitTableConf(this.props.i18n).find(i => i.feeType === +data?.fee_type) || {};
      stateObj.feeConfig = feeConfig;
    }
    this.setState(stateObj, () => {
      // 初始化费用配置信息
      if (first && id) {
        const feeIdItem = enumerations?.fee_id?.find(i => +i.key === +data.fee_id);
        this.setFieldsData('fee_id', feeIdItem, true);
      }
    });
  };
  orgSug = keyword => orgSug({ search: keyword });
  batchSug = keyword =>
    carBatch({
      keyword,
      tab: 'batch_fee_sug',
      cid: this.state.data.company_id?.id || this.state.data.company_id,
    });
  truckSug = keyword =>
    truckSug({
      keyword,
      is_tr_header: 1,
      range: 'all',
      cid: this.state.data.company_id?.id || this.state.data.company_id,
    });
  driverSug = keyword => driverSug({ keyword, cid: this.state.data.company_id?.id || this.state.data.company_id });
  // 油费-加油站点
  serviceProviderSug = keyword =>
    serviceProviderSug({ type: 1, cid: this.state.data.company_id?.id || this.state.data.company_id, keyword });
  entranceExitOilStationSug = (keyword, key) => {
    const { feeConfig } = this.state;
    const typeMap = {
      site_exit: feeConfig?.key === 'inTransitUreaFee' ? 'station' : 'exit', // 尿素费-站点 路桥费-出站口
      km_num_entrance: 'entrance', // 路桥费-入站口
    };
    return entranceExitOilStationSug({
      type: typeMap[key],
      cid: this.state.data.company_id?.id || this.state.data.company_id,
      keyword,
    });
  };
  oilCardSug = (keyword, params) => {
    let req = {
      filter: [{ parent_id: 0, _logic: 'not' }],
      cid: this.state.data.company_id?.id || this.state.data.company_id,
      dr_id: this.state.data.dr_id?.id || this.state.data.dr_id,
      tr_id: this.state.data.tr_id?.id || this.state.data.tr_id,
      keyword,
      is_fuelcard_range: true, // 判断油卡是否读取系统设置显示
    };
    if (typeof params === 'object') {
      req = {
        ...req,
        ...params,
      };
    }
    return oilCardSug(req);
  };
  etcSug = keyword =>
    etcSug({
      keyword,
      cid: this.state.data.company_id?.id || this.state.data.company_id,
      tr_id: this.state.data.tr_id?.id || this.state.data.tr_id,
    });

  handleBlur = () => {
    // eslint-disable-next-line no-return-assign
    ['amount', 'unit_price', 'quantity'].forEach(item => (this[`${item}_calc`] = false));
  };
  setFieldsData = async (key, val, init = false) => {
    const { opType } = this.props;
    // eslint-disable-next-line unused-imports/no-unused-vars
    const { data, originData, enums, originEnums, feeConfig } = this.state;
    let onwayFeeField = null;
    let callback = null;
    // 切换组织清空数据
    if (key === 'company_id' && val && +val.id !== (+data.company_id?.id || +data.company_id)) {
      this.setState(
        {
          data: {
            company_id: val,
          },
        },
        () => {
          this.getData();
        },
      );
      return;
    }
    // 切换费用名称，带出费用配置信息，包括费用字段配置以及支付方式、加油站点
    if (key === 'fee_id' && val) {
      onwayFeeField = val.onway_fee_field;
      if (val.fee_income_expend_way) {
        enums.income_expend_way = val.fee_income_expend_way;
        // 当前值不在枚举中则清空,修改时第一次打开不清数据
        if (
          val.fee_income_expend_way?.findIndex(
            i => i.name === (data.income_expend_way?.name || data.income_expend_way),
          ) === -1 &&
          !init
        ) {
          data.income_expend_way = null;
        }
      } else {
        enums.income_expend_way = originEnums.income_expend_way;
      }
      if (feeConfig?.fields?.site_exit) {
        if (val.fee_oil_station) {
          enums.site_exit = val.fee_oil_station;
          // 当前值不在枚举中则清空,修改时第一次打开不清数据
          if (
            val.fee_oil_station?.findIndex(i => i.name === (data.site_exit?.name || data.site_exit)) === -1 &&
            !init
          ) {
            data.site_exit = null;
          }
        } else {
          enums.site_exit = null;
        }
      }
      if (feeConfig?.key === 'inTransitOilFee' && !init) {
        const tplShow = _.get(window, 'order_tp[3].setting.ros_set.ctt_show');
        const showSetting = onwayFeeField || _.get(tplShow, `[${feeConfig?.settingKey}].__meta.fields`, {});
        if (showSetting?.happen_time?.show && showSetting?.site_exit?.show && data.happen_time && data.site_exit) {
          if (!enums.goods_price?.length) {
            callback = this.getGoodsPrice;
          }
        } else {
          data.goods_price = null;
          enums.goods_price = [];
        }
      }
      this.setState({
        onwayFeeField,
        show_gps_miles: val.show_gps_miles,
      });
    }
    // 选择运单自动带出车牌、司机
    if (key === 'batch') {
      if (val) {
        data.tr_id = +val.b_tr_id || null;
        enums.tr_id = [
          {
            id: val.b_tr_id,
            tr_num: val.b_tr_num,
          },
        ];
        // 司机上报(source: 2)的费用不带出司机，司机保持不变
        // 若司机不展示，则不需要带出
        if (+data.source !== 2 && this.isShow('dr_id')) {
          data.dr_id = +val.b_dr_id || null;
          enums.dr_id = [
            {
              id: val.b_dr_id,
              name: val.b_dr_name,
            },
          ];
        }
      } else {
        data.b_basic_id = null;
        data.b_link_id = null;
        data.batch_company_id = null;
      }
    }
    // 选择商品带出价格
    if (key === 'goods_price' && val) {
      this.setFieldsData('unit_price', val.settle_price);
    }
    // 选择车辆带出司机
    if (key === 'tr_id') {
      // 司机端上报的不可以修改司机
      if (+val.dr_com_id && +data.source !== 2 && this.isShow('dr_id')) {
        data.dr_id = +val.dr_com_id;
        enums.dr_id = [
          {
            id: val.dr_com_id,
            name: val.dr_name,
          },
        ];
      }
    }
    // 金额计算需要用到实时值，所以先改data的值
    data[key] = val;
    if (feeConfig?.key === 'inTransitOilFee') {
      // 司机/车牌号改变油卡号联动
      if (['batch', 'tr_id', 'dr_id'].includes(key)) {
        const params = key === 'tr_id' || key === 'dr_id' ? { [key]: data[key]?.id || data[key] } : '';
        const oilRes = await this.oilCardSug(undefined, params);
        const isInClude = (oilRes || []).filter(
          item => item.card_num === data.card_num?.card_num || item.card_num === data.card_num,
        ).length;
        if (!isInClude) {
          // eslint-disable-next-line require-atomic-updates
          data.card_num = '';
        }
      }
      // 获取商品名称
      if (['happen_time', 'site_exit'].includes(key)) {
        if (this.isShow('happen_time') && this.isShow('site_exit') && data.happen_time && data.site_exit) {
          callback = this.getGoodsPrice;
        } else {
          enums.goods_price = [];
          // eslint-disable-next-line require-atomic-updates
          data.goods_price = null;
        }
      }
    }
    const oilPriceMaps = {
      amount: Big(+data.unit_price || 0)
        .times(+data.quantity || 0)
        .toFixed(2),
      unit_price: +data.quantity
        ? Big(+data.amount || 0)
            .div(+data.quantity)
            .toFixed(2)
        : '',
      quantity: +data.unit_price
        ? Big(+data.amount || 0)
            .div(+data.unit_price)
            .toFixed(2)
        : '',
    };
    this[`${key}_calc`] && (this[`${key}_calc`] = false);
    // 有商品时，金额 = 单价 * 数量
    if (enums.goods_price?.length && oilPriceMaps[key] !== undefined) {
      if (data.unit_price && data.quantity) {
        data.amount = oilPriceMaps.amount;
      }
    } else {
      // 添加费用时，金额、油价、加油量填写任意两个计算第三个
      // eslint-disable-next-line no-lonely-if
      if (opType === 'add' || opType === 'edit') {
        if (oilPriceMaps[key] !== undefined && val) {
          const notEdited = Object.keys(oilPriceMaps).filter(i => i !== key && (!data[i] || this[`${i}_calc`]));
          if (notEdited.length === 1) {
            data[notEdited[0]] = oilPriceMaps[notEdited[0]];
            this[`${notEdited[0]}_calc`] = true; // 标记是否为计算出来的值
          }
        }
      }
    }
    this.setState(
      {
        data: {
          ...data,
        },
        enums: {
          ...enums,
        },
      },
      () => {
        callback && callback();
      },
    );
  };

  // 油价、金额、升数点击计算
  computeFieldsData = key => {
    const { data = {}, feeConfig } = this.state;
    const amountText = feeConfig?.fields?.amount?.label;
    const unitPriceText = feeConfig?.fields?.unit_price?.label;
    const quantityText = feeConfig?.fields?.quantity?.label?.split('(')?.[0];
    // eslint-disable-next-line default-case
    switch (key) {
      case 'quantity':
        if (!data.amount || !data.unit_price) return showInfo(ERROR, `请先填写${amountText}和${unitPriceText}`);
        if (+data.unit_price === 0) return showInfo(ERROR, `${unitPriceText}不能为零`);
        data.quantity = Big(+data.amount || 0)
          .div(+data.unit_price)
          .toFixed(2);
        break;
      case 'unit_price':
        if (!data.amount || !data.quantity) return showInfo(ERROR, `请先填写${amountText}和${quantityText}`);
        if (+data.quantity === 0) return showInfo(ERROR, `${quantityText}不能为零`);
        data.unit_price = Big(+data.amount || 0)
          .div(+data.quantity)
          .toFixed(2);
        break;
      case 'amount':
        if (!data.unit_price || !data.quantity) return showInfo(ERROR, `请先填写${unitPriceText}和${quantityText}`);
        data.amount = Big(+data.unit_price || 0)
          .times(+data.quantity || 0)
          .toFixed(2);
        break;
    }
    this.setState({ data });
  };

  batchFeeView = () => {
    const { pre_approval_id } = this.state?.relation_info?.gps_info?.data || {};
    new PopUp(withPopUpI18n(FeeReport), {
      popName: 'batchFeeView_relation',
      opType: 'view',
      id: pre_approval_id,
    }).show();
  };

  setGpsMiles = val => {
    const { relation_info } = this.state;
    if (val >= 1000000) {
      return showInfo(ERROR, '公里数不能超过1000000');
    }
    _.set(relation_info, 'gps_info.data.gps_miles', val);
    this.setState({
      relation_info: {
        ...relation_info,
      },
    });
  };

  onFocus = (key, val) => {
    if (key === 'gps_miles') {
      this.oldGpsMiles = val;
    }
  };

  onBlur = async (key, val) => {
    if (key === 'gps_miles') {
      if (this.oldGpsMiles === val) return;
      if (!(await validateFieldsOf(this.relationRef))) return;
      const { relation_info } = this.state;
      const req = {
        id: relation_info?.gps_info?.data?.id,
        calc_type: 2, // 1: 自动获取 2: 手动编辑
        gps_miles: relation_info?.gps_info?.data?.gps_miles,
      };
      try {
        const res = await fetchJAVA('/cmm-basic/onwayfeegpsinfo/update', {
          method: 'POST',
          body: {
            req,
          },
        });
        if (+res.errno !== 0) {
          return showInfo(ERROR, res.errmsg);
        } else {
          _.set(relation_info, 'gps_info.data.calc_type', 2);
          this.setState({
            relation_info: {
              ...relation_info,
            },
          });
        }
      } catch (e) {
        showInfo(ERROR, '请求失败，请稍后重试或联系管理员！');
      }
    }
  };

  refreshGPS = async () => {
    const { id } = this.props;
    const req = {
      ids: [id],
    };
    try {
      this.setState({ loading: true });
      this.props?.setLoading?.(true);
      const res = await fetchJAVA('/cmm-basic/approval/recal_gps_miles', {
        method: 'POST',
        body: {
          req,
        },
      });
      this.setState({ loading: false });
      this.props?.setLoading?.(false);
      if (+res.errno !== 0) {
        return showInfo(ERROR, res.errmsg);
      }
      const relation_info = {
        gps_info: res?.res,
      };
      this.setState({
        relation_info,
      });
    } catch (e) {
      showInfo(ERROR, '请求失败，请稍后重试或联系管理员！');
    }
  };

  getGoodsPrice = async () => {
    const { data, enums } = this.state;
    const req = { price_time: data.happen_time, oil_station_name: data.site_exit?.name || data.site_exit };
    const res = await fetchJAVA('/cyt-vehicle-biz-v1/sp/price/onway/prices', {
      method: 'POST',
      body: {
        req,
      },
    });
    const list = res?.res?.list;
    enums.goods_price = list;

    let defaultGoods = list.find(i => i.goods_name === data.goods_price?.goods_name);
    if (list.length === 1) defaultGoods = list[0];
    if (defaultGoods) {
      this.setFieldsData('goods_price', defaultGoods);
    } else {
      // eslint-disable-next-line require-atomic-updates
      data.goods_price = null;
    }
    this.setState({
      data: {
        ...data,
      },
      enums: {
        ...enums,
      },
    });
  };

  onSubmit = async () => {
    const { opType, id } = this.props;
    const { feeConfig, data, enums, loading } = this.state;
    if (loading) return;
    if (!(await validateFieldsOf(this.wrap))) {
      return;
    }
    if (this.isShow('happen_time') && this.isRequired('happen_time') && !data.happen_time) {
      return showInfo(ERROR, '请选择时间');
    }
    if (+data.amount === 0) {
      return showInfo(ERROR, '金额不能为0');
    }
    if (this.isShow('tank_full') && data.tank_full === null) {
      return showInfo(ERROR, '请选择满油标识');
    }
    if (this.isShow('pic') && this.isRequired('pic') && !data.pic?.length) {
      return showInfo(ERROR, '请上传图片');
    }
    const save = async () => {
      const saveData = {};
      Object.keys(data).forEach(key => {
        if (this.isShow(key)) {
          saveData[key] = data[key];
          if (key === 'batch') {
            saveData.b_link_id = data.b_link_id;
            saveData.b_basic_id = data.b_basic_id;
            saveData.batch_company_id = data.batch_company_id;
          }
          if (key === 'tr_id') {
            saveData.tr_num = data.tr_num;
          }
          if (key === 'dr_id') {
            saveData.dr_name = data.dr_name;
          }
        }
        // 如果有商品，不管油价是否显示，都需要保存
        if (enums.goods_price?.length) {
          saveData.unit_price = data.unit_price;
        }
      });
      const apply_data = {
        ...saveData,
        source: data.source || 1,
        company_id: saveData.company_id?.id || saveData.company_id,
        b_basic_id: saveData.batch?.b_basic_id || saveData.b_basic_id,
        b_link_id: saveData.batch?.b_link_id || saveData.b_link_id,
        batch_company_id: saveData.batch?.company_id || saveData.batch_company_id,
        tr_id: saveData.tr_id?.id || saveData.tr_id,
        tr_num: saveData.tr_id?.tr_num || saveData.tr_num,
        dr_id: saveData.dr_id?.id || saveData.dr_id,
        dr_name: saveData.dr_id?.name || saveData.dr_name,
        card_num: saveData.card_num?.card_num || saveData.card_num?.number || saveData.card_num,
        fee_id: saveData.fee_id?.key || saveData.fee_id,
        db_key: saveData.fee_id?.ext_info?.db_key || saveData.db_key,
        fee_type: saveData.fee_id?.ext_info?.type || saveData.fee_type,
        tank_full: saveData.tank_full,
        income_expend_way: saveData.income_expend_way?.id || saveData.income_expend_way,
        site_exit: saveData.site_exit?.name || saveData.site_exit,
        batch: undefined,
      };
      // 在途费用支持选择下级审批人
      const appoint_user = await assignedApprover({
        apply_data,
        apply_id: id,
        operation: 'fee_report_apply',
      });
      if (!appoint_user) return;
      this.setState({ loading: true });
      const res = await fetchJAVA('/cmm-batch/japi/batch/transitFee/employee/report', {
        method: 'POST',
        body: {
          req: {
            apply_id: id,
            apply_data,
            appoint_user: appoint_user?.id,
          },
        },
      });
      if (res.errno !== 0) {
        showInfo(ERROR, res.errmsg);
        this.setState({ loading: false });
      } else {
        const tip = {
          add: '新增',
          edit: '修改',
        };
        const tipsTxt = res?.res?.reseted
          ? '提交成功，费用按最新流程进行审批'
          : `${tip[opType]}${feeConfig?.title}成功`;
        showInfo(CHECK, tipsTxt);
        this.handleHide();
        this.props.onSuccess && this.props.onSuccess(res?.res?.id);
        this.setState({ loading: false });
      }
    };
    // 修改前确认弹框
    if (opType === 'edit') {
      this.setState({ loading: true });
      const res = await fetchJAVA('/cmm-basic/japi/batch_onwayfee_share/amount_modify/share_preview', {
        method: 'POST',
        body: {
          req: [
            {
              apply_id: id,
              new_value: data.amount,
            },
          ],
        },
      });
      if (res?.res?.list?.length === 0) {
        return save();
      }
      const contentNotice = (
        <div>
          <table>
            <thead>
              <tr>
                <th>运单号</th>
                <th>原分摊金额</th>
                <th>修改后分摊金额</th>
              </tr>
            </thead>
            <tbody>
              {res?.res?.list.map(i => (
                <tr>
                  <td>{i.car_batch}</td>
                  <td>{i.old_value}</td>
                  <td>{i.new_value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
      this.setState({ loading: false });
      new PopUp(ShowInfo, {
        type: INFO,
        contentNotice,
        content: '修改后，该费用分摊的金额会发生如下改变，请您知晓',
        title: '提示',
        showIcon: true,
        maskClosable: false,
        popName: 'feeShareChange',
        simpleMode: {
          confirm: thisDialog => {
            save();
            thisDialog.handleHide();
          },
          cancel: thisDialog => thisDialog.handleHide(),
        },
      }).show();
    } else {
      save();
    }
  };
  handleHide = () => this._slideDrager?.handleHide?.();
  isShow = key => {
    const { feeConfig, onwayFeeField } = this.state;
    const fixShow = ['company_id', 'fee_id', 'tr_id', 'goods_price'];
    const tplShow = _.get(window, 'order_tp[3].setting.ros_set.ctt_show');
    const showSetting = onwayFeeField || _.get(tplShow, `[${feeConfig?.settingKey}].__meta.fields`, {});
    return _.get(showSetting, `[${key}].show`) || fixShow.includes(key);
  };
  isRequired = key => {
    const { feeConfig, onwayFeeField, enums } = this.state;
    let fixRequired = ['company_id', 'fee_id', 'tr_id', 'tank_full', 'amount'];
    const tplShow = _.get(window, 'order_tp[3].setting.ros_set.ctt_show');
    const showSetting = onwayFeeField || _.get(tplShow, `[${feeConfig?.settingKey}].__meta.fields`, {});
    const goods_price_index = fixRequired.findIndex(i => i === 'goods_price');
    const quantity_index = fixRequired.findIndex(i => i === 'quantity');
    if (enums.goods_price?.length) {
      fixRequired = Array.from(new Set([...fixRequired, 'goods_price', 'quantity']));
    } else if (goods_price_index > -1) {
      fixRequired.splice(goods_price_index, 1);
      fixRequired.splice(quantity_index, 1);
    }
    return _.get(showSetting, `[${key}].required`) || fixRequired.includes(key);
  };
  isDisabled = key => {
    const { opType } = this.props;
    const { data, enums } = this.state;
    if (opType === 'edit') {
      if (key === 'company_id') {
        return true;
      }
      // 司机端上报不可以修改司机
      if (key === 'dr_id' && +data.source === 2) {
        return true;
      }
    }
    if (opType === 'edit' || opType === 'add') {
      if (key === 'goods_price') {
        return (enums.goods_price || []).length === 0;
      }
      if (key === 'amount' || key === 'unit_price') {
        return (enums.goods_price || []).length > 0;
      }
    }
    return opType === 'view';
  };
  edited = key => {
    const ele = this.wrap.querySelector(`[data-path="${key}"]`);
    return ele && ele.dataset.isEdited;
  };
  isShowIcon = key => {
    const { feeConfig, enums } = this.state;
    const feeFields = feeConfig?.fields || {};
    if (['quantity', 'unit_price'].includes(key)) {
      return (enums.goods_price || []).length > 0;
    }
    return !!feeFields[key].icon;
  };
  // 如果存在关联带出的本地加油站点，则使用前端搜索
  relateSiteProviderSug = k => {
    const { enums } = this.state;
    const { site_exit = [] } = enums ?? {};
    return k ? _.filter(site_exit, item => item.name.indexOf(k) > -1) : site_exit;
  };

  getRepeatFeeVisible = () => {
    return new Promise((resolve, reject) => {
      feeReportStore
        .getItem(feeRepeatKey)
        .then(data => {
          resolve(data);
        })
        .catch(function (err) {
          reject(err);
        });
    });
  };

  setRepeatFeeVisible = isVisible => {
    feeReportStore
      .setItem(feeRepeatKey, isVisible)
      .then(() => {
        this.setState({ repeatFeeVisible: isVisible });
      })
      .catch(error => {
        console.log('error: ', error);
      });
  };

  viewSimilarFee = () => {
    const { repeatFeeVisible, hasSuspDupes } = this.state;
    const { id = '' } = this.props;

    if (!repeatFeeVisible || !hasSuspDupes) {
      return;
    }

    new PopUp(ModalDialog, {
      title: (
        <React.Fragment>
          <i className="fn-icon fn-icon-warn-o" />
          <div>相似费用</div>
        </React.Fragment>
      ),
      content: <SimilarFee id={id} />,
      popName: 'has_susp_dupes',
      isDrag: false,
      isShow: true,
      style: { width: 900 },
    }).show();
  };

  renderContent() {
    const { data, enums, relation_info, feeConfig, show_gps_miles, repeatFeeVisible, hasSuspDupes } = this.state;
    const { onlyShowContent, opType, isAudit } = this.props;
    const { pre_number, gps_miles, update_time, calc_type } = relation_info?.gps_info?.data || {};
    const { fail_msg } = relation_info?.gps_info?.fail_info || {};
    const feeFields = feeConfig?.fields || {};
    const showRelationInfo = +show_gps_miles === 1 && onlyShowContent;
    if (enums.site_exit) {
      feeFields.site_exit.type = 'Select';
      feeFields.site_exit.filter = 'relateSiteProviderSug'; // 如果存在关联带出的本地加油站点，则使用前端搜索
      feeFields.site_exit.compare = 'name';
    }

    return (
      // eslint-disable-next-line no-return-assign
      <div className={prefixCls} ref={ref => (this.wrap = ref)}>
        {isAudit && (
          <div className={cls('repeat-fee')}>
            <div
              onClick={() => this.viewSimilarFee()}
              className={`repeat-content ${repeatFeeVisible && hasSuspDupes ? '' : 'hidden'}`}
            >
              <Icon classname="icon" iconType="icon-warn-o" />
              <span className="text">疑似重复报销</span>
            </div>
            <div className="switch-wrap">
              <label className="item-txt">展示疑似重复报销提醒</label>
              <Switch
                wrapClass="item-switch-wrap"
                onClick={val => this.setRepeatFeeVisible(val)}
                checked={repeatFeeVisible}
              />
            </div>
          </div>
        )}
        <CardBox title="基本信息">
          {Object.keys(feeFields)
            .filter(key => this.isShow(key))
            .map(key => (
              <FormItem
                required={this.isRequired(key)}
                label={feeFields[key].label}
                className={feeFields[key].className || ''}
                key={key}
              >
                {feeFields[key].type === 'Input' && (
                  <>
                    <PureInput
                      value={data[key]}
                      data-path={key}
                      pattern={feeFields[key].pattern}
                      required={this.isRequired(key)}
                      disabled={this.isDisabled(key)}
                      onChange={e => this.setFieldsData(key, e.target.value)}
                      onBlur={this.handleBlur}
                    />
                    {this.isShowIcon(key) && <Icon iconType={feeFields[key].icon} tips={feeFields[key].tip} />}
                    {feeFields[key].computerIcon && opType !== 'view' && (
                      <Icon iconType={feeFields[key].computerIcon} onClick={() => this.computeFieldsData(key)} />
                    )}
                  </>
                )}
                {feeFields[key].type === 'Select' && (
                  <>
                    <Select
                      value={data[key]}
                      data-path={key}
                      data={enums[key] || []}
                      required={this.isRequired(key)}
                      disabled={this.isDisabled(key)}
                      compare={feeFields[key].compare}
                      onChange={val => {
                        this.setFieldsData(key, val);
                      }}
                      filter={keyword => this[feeFields[key].filter]?.(keyword, key)}
                      format={feeFields[key].format}
                      header={feeFields[key].header}
                      map={false}
                    />
                    {this.isShowIcon(key) && <Icon iconType={feeFields[key].icon} tips={feeFields[key].tip} />}
                  </>
                )}
                {feeFields[key].type === 'DataList' && (
                  <DataList
                    value={data[key]?.[feeFields[key].map] || data[key]}
                    data={enums[key] || []}
                    required={this.isRequired(key)}
                    disabled={this.isDisabled(key)}
                    onChange={val => {
                      this.setFieldsData(key, val);
                    }}
                    onSelect={val => {
                      this.setFieldsData(key, val[feeFields[key].map]);
                    }}
                    filter={keyword => this[feeFields[key].filter]?.(keyword, key)}
                    header={feeFields[key].header}
                  />
                )}
                {feeFields[key].type === 'Time' && (
                  <DateTimePicker
                    defaultValue={data[key]}
                    required={this.isRequired(key)}
                    disabled={this.isDisabled(key)}
                    onChange={val => this.setFieldsData(key, val)}
                    showTime
                  />
                )}
                {feeFields[key].type === 'Radio' && (
                  <Radio
                    data={[
                      { value: true, label: '加满了', name: 'tank_full' },
                      { value: false, label: '没加满', name: 'tank_full' },
                    ]}
                    value={data[key]}
                    disabled={this.isDisabled(key)}
                    onChange={val => this.setFieldsData(key, val)}
                  />
                )}
                {feeFields[key].type === 'TextArea' && (
                  <PureTextarea
                    value={data[key]}
                    max-length="100"
                    placeholder="请填写备注..."
                    onChange={e => this.setFieldsData(key, e.target.value)}
                    required={this.isRequired(key)}
                    disabled={this.isDisabled(key)}
                  />
                )}
                {feeFields[key].type === 'UploadImgText' && (
                  <UploadFile
                    fileList={data[key]}
                    isRequired={this.isRequired(key)}
                    disabled={this.isDisabled(key)}
                    fileType="feeReport"
                    maxNum={20}
                    showType="img"
                    accept="image/*"
                    onChange={val => this.setFieldsData(key, val)}
                  />
                )}
              </FormItem>
            ))}
        </CardBox>
        {showRelationInfo && (
          <CardBox title="关联信息" className={cls('relation_info')}>
            <div
              className="card-wrapper"
              ref={r => {
                this.relationRef = r;
              }}
            >
              <div className="relation-content">
                {!fail_msg && pre_number && (
                  <div className="relation-left">
                    <div>
                      上条在途费用编号：
                      <span className="link" onClick={this.batchFeeView}>
                        {pre_number}
                      </span>
                    </div>
                    <div>
                      距上条在途费用的GPS里程：
                      <PureInput
                        value={gps_miles}
                        pattern={FLOAT_P_6}
                        className="fn-input-pure-gps_miles"
                        onChange={e => this.setGpsMiles(e.target.value)}
                        onFocus={e => this.onFocus('gps_miles', e.target.value)}
                        onBlur={e => this.onBlur('gps_miles', e.target.value)}
                        suffix="km"
                      />
                    </div>
                    {+calc_type === 2 && <div>（已编辑）</div>}
                  </div>
                )}
                {fail_msg && <div>{fail_msg}</div>}
                {!fail_msg && !pre_number && <div />}
                <Icon iconType="icon-refresh" tips='更新"距上条费用的GPS里程"' onClick={this.refreshGPS} />
              </div>
              {!fail_msg && update_time && <div className="time">更新时间：{update_time}</div>}
            </div>
          </CardBox>
        )}
      </div>
    );
  }
  renderFooter = () => {
    const { loading } = this.state;
    if (this.props.opType === 'view') {
      return (
        <div className="slide__drager__footer__content">
          <Button type="primary" onClick={() => this.handleHide()} loading={loading}>
            关闭
          </Button>
        </div>
      );
    }
    return (
      <div className="slide__drager__footer__content">
        <Button onClick={this.onSubmit} type="primary" loading={loading}>
          保存
        </Button>
        <Button onClick={this.handleHide}>取消</Button>
      </div>
    );
  };
  render() {
    const { opType, onlyShowContent } = this.props;
    const { feeConfig, common_approval } = this.state;
    const opText = {
      add: `新增${feeConfig?.title || ''}`,
      view: common_approval?.number || `查看${feeConfig?.title || ''}`,
      edit: common_approval?.number || `修改${feeConfig?.title || ''}`,
    };
    return (
      <div>
        {onlyShowContent ? (
          this.renderContent()
        ) : (
          <div className={cls('')}>
            <SlideDrager
              isShow
              slideWidth={740}
              // eslint-disable-next-line no-return-assign
              ref={ref => (this._slideDrager = ref)}
              close={this.props.close}
              HeaderTitle={opText[opType]}
              contentDiv={this.renderContent()}
              footerDiv={this.renderFooter()}
            />
          </div>
        )}
      </div>
    );
  }
}
export default withPopUpI18n(FeeReport);
